import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import {MafiFilterEntry} from "../components/mafiSearch/filterElement.component";
import {showApiError} from "../misc/errorHelper";
import {allLoadingStatus} from "../misc/filterOptions";
import LocalForageHelper from "../misc/localforageHelper";
import {setShowLoading} from "../store/slices/global";
import {setLastMafiSync} from "../store/slices/mafi";
import {store} from "../store/store";
import {MafiCategory, MafiInformation, parseNotations, MafiOwner, MafiPrefix} from "@blg/blg-core";

interface SearchOptions {
    mafiNo?: string,
    mafiPrefix?: string,
    mafiOwner?: string,
    mafiCategories: string[],
    mafiLength: MafiFilterEntry[],
    mafiMaxWeightLoad: MafiFilterEntry[],
    lashingMethods: string[],
    loadingStatus: string[],
    showOnlyPoolMafi: boolean,
    showAHB: boolean,
    pageIndex?: number,
    pageLimit?: number
}

interface PageInfo {
    emptyResult: boolean
    firstPage: boolean
    lastPage: boolean
    numberOfElements: number
    pageNumber: number
    pageSize: number
    totalElements: number
    totalPages: number
}

export interface AvailableSearchOptions {
    owner: string[],
    prefix: string[],
    category: MafiCategory[],
    length: MafiFilterEntry[],
    tara: MafiFilterEntry[],
    lashingMethods: string[]
}

interface SeenSearchObjects {
    [key: string]: boolean;
}

export default class MafiSearchHelper {
    private static _instance?: MafiSearchHelper; // Used for the singleton pattern

    private allMafis: MafiInformation[] = [];

    constructor() {
        if (MafiSearchHelper._instance) {
            return MafiSearchHelper._instance;
        }

        MafiSearchHelper._instance = this;
    }

    // Create the singleton
    static get instance() {
        return MafiSearchHelper._instance ?? (MafiSearchHelper._instance = new MafiSearchHelper());
    }


    /**
     * Sync all the data to handle the app in the offline mode
     * Mafis, SearchSettings
     */
    public async syncOfflineData(payload: {hideLoadingText?: boolean, showLoadingAnimation?: boolean}): Promise<void> {
        if (payload.showLoadingAnimation) {
            store.dispatch(setShowLoading({showLoading: true, loadingText: payload.hideLoadingText ? undefined :  "Stammdaten werden synchronisiert"}));
        }
        try {
            let mafis = await TugService.instance.mafiAll({});

            // Filter duplicates by mafiNo
            mafis = mafis.filter((mafi, index, self) => self.findIndex(t => t.mafiNo === mafi.mafiNo) === index)

            await LocalForageHelper.instance.setAllMafis(mafis);

            store.dispatch(setLastMafiSync(new Date().toISOString()));

            const notations = await TugService.instance.getAllNotations();
            await LocalForageHelper.instance.setAllMafiNotations(notations);

            const settingsResponse = await TugService.instance.loadMafiSearchSettings();
            const mafiCategories = MafiCategory.parseFromArray(settingsResponse.data.categoryList) as MafiCategory[];
            const mafiOwners = MafiOwner.parseFromArray(settingsResponse.data.ownerList) as MafiOwner[];
            const mafiPrefix = MafiCategory.parseFromArray(settingsResponse.data.prefixList) as MafiPrefix[];
            await LocalForageHelper.instance.setSearchSettings(mafiOwners, mafiPrefix, mafiCategories);

            const result = parseNotations(notations);
            await LocalForageHelper.instance.setParsedNotations(result);
        } catch (e) {
            showApiError(e);
        } finally {
            store.dispatch(setShowLoading({showLoading: false, loadingText: undefined}));
        }
    }

    private async getOfflineByStackNo(stackNo: string): Promise<MafiInformation[]> {
        return this.allMafis.filter(mafi => mafi.stackNo === stackNo);
    }

    private async handleOfflineSearchParams(searchOptions: SearchOptions): Promise<MafiInformation[]> {
        let allMafis = await LocalForageHelper.instance.loadAllMafis();
        this.allMafis = allMafis;
        if (searchOptions.mafiNo) {
            allMafis = allMafis.filter(mafi => mafi.mafiNo.toLowerCase().includes(searchOptions.mafiNo!.toLowerCase()));
        }
        if (searchOptions.mafiOwner && searchOptions.mafiOwner !== "ALL") {
            allMafis = allMafis.filter(mafi => mafi.mafiOwnerCode === searchOptions.mafiOwner);
        }
        if (searchOptions.mafiPrefix && searchOptions.mafiPrefix !== "ALL") {
            allMafis = allMafis.filter(mafi => mafi.mafiNo.toLowerCase().startsWith(searchOptions.mafiPrefix!.toLowerCase()));
        }
        if (searchOptions.mafiCategories.length) {
            allMafis = allMafis.filter(mafi => searchOptions.mafiCategories.includes(mafi.categoryCode));
        }

        if (searchOptions.mafiLength.length) {
            allMafis = allMafis.filter(mafi => searchOptions.mafiLength.some(length => mafi.length >= length.min && (!length.max || mafi.length <= length.max)));
        }

        if (searchOptions.mafiMaxWeightLoad.length) {
            allMafis = allMafis.filter(mafi => searchOptions.mafiMaxWeightLoad.some(ta => mafi.weightMaxLoad >= ta.min && (!ta.max || mafi.weightMaxLoad <= ta.max)));
        }

        if (searchOptions.lashingMethods.length) {
            allMafis = allMafis.filter(mafi => searchOptions.lashingMethods.some(lm => mafi.lashingMethod === lm));
        }

        if (searchOptions.loadingStatus.length) {
            allMafis = allMafis.filter(mafi => searchOptions.loadingStatus.some(lm => mafi.loadStatus === lm));
        }

        if (searchOptions.showOnlyPoolMafi) {
            allMafis = allMafis.filter(mafi => mafi.pool);
        }
        /*
        if (!searchOptions.showAHB) {
            allMafis = allMafis.filter(mafi => !mafi.mafiLocation.location.includes('AHB'));
        }
        */
        return allMafis
    }

    public async handleMafiSearch(searchObject: SearchOptions, allPrefixes: string[], allLengths: MafiFilterEntry[], allTaras: MafiFilterEntry[], allLashingMethods: string[], allCategories: MafiCategory[]): Promise<{
        stackedMafis: MafiInformation[], searchMafis: MafiInformation[], pageInfo: PageInfo, availableSearchOptions: AvailableSearchOptions
    }> {
        const searchMafis = await this.handleOfflineSearchParams(searchObject);

        // Find the new search params for the found mafis
        // Get the owner filter options
        const uniqueMafiOwnerCodes: string[] = [];
        const seenMafiOwnerCodes: SeenSearchObjects = {};

        // Get the prefix filter options
        const uniqueMafiPrefixes: string[] = [];
        const seenMafiPrefixes: SeenSearchObjects = {};

        // Get the category filter options
        const uniqueCategories: MafiCategory[] = [];
        const seenCategories: SeenSearchObjects = {};

        // Get the length filter options
        const uniqueLengths: MafiFilterEntry[] = [];
        const seenLengths: SeenSearchObjects = {};

        // Get the tara filter options
        const uniqueTara: MafiFilterEntry[] = [];
        const seenTara: SeenSearchObjects = {};

        // Get the lashing method filter options
        const uniqueLashingMethods: string[] = [];
        const seenLashingMethods: SeenSearchObjects = {};

        // Get the lashing method filter options
        const seenLoadingStatus: SeenSearchObjects = {};

        for (const item of searchMafis) {
            if (item.mafiOwnerCode && !seenMafiOwnerCodes[item.mafiOwnerCode]) {
                seenMafiOwnerCodes[item.mafiOwnerCode] = true;
                uniqueMafiOwnerCodes.push(item.mafiOwnerCode);
            }

            if (item.categoryCode && !seenCategories[item.categoryCode]) {
                seenCategories[item.categoryCode] = true;
                const cat = allCategories.find(c => c.category === item.categoryCode);
                if (cat) {
                    uniqueCategories.push(cat);
                }
            }

            for (const prefix of allPrefixes) {
                if (!seenMafiPrefixes[prefix] && item.mafiNo.toLowerCase().startsWith(prefix.toLowerCase())) {
                    seenMafiPrefixes[prefix] = true;
                    uniqueMafiPrefixes.push(prefix);
                }
            }

            for (const length of allLengths) {
                if (item.length && !seenLengths[length.id] && item.length >= length.min && (!length.max || item.length <= length.max)) {
                    seenLengths[length.id] = true;
                    uniqueLengths.push(length);
                }
            }

            for (const taraEntry of allTaras) {
                if (!seenTara[taraEntry.id] && item.weightMaxLoad >= taraEntry.min && (!taraEntry.max || item.weightMaxLoad <= taraEntry.max)) {
                    seenTara[taraEntry.id] = true;
                    uniqueTara.push(taraEntry);
                }
            }

            for (const lashingMethod of allLashingMethods) {
                if (!seenLashingMethods[lashingMethod] && item.lashingMethod === lashingMethod) {
                    seenLashingMethods[lashingMethod] = true;
                    uniqueLashingMethods.push(lashingMethod);
                }
            }

            for (const loadingStatus of allLoadingStatus) {
                if (!seenLoadingStatus[loadingStatus] && item.loadStatus === loadingStatus) {
                    seenLoadingStatus[loadingStatus] = true;
                }
            }
        }

        const stacksToAdd: Array<string> = []
        searchMafis.forEach((mafi: MafiInformation) => {
            if (mafi.stackNo) {
                if (!stacksToAdd.includes(mafi.stackNo)) {
                    stacksToAdd.push(mafi.stackNo)
                }
            }
        });
        const additionalMafis: Array<MafiInformation> = [];
        const promises = stacksToAdd.map(async (stack) => {
            const mafiInStack = await this.getOfflineByStackNo(stack);
            mafiInStack.forEach(mafi => {
                if (!searchMafis.find(additionalMafi => additionalMafi.mafiNo === mafi.mafiNo)) {
                    additionalMafis.push(mafi);
                }
            })
        })
        await Promise.all(promises);

        return new Promise(resolve => resolve({
                searchMafis: searchMafis, stackedMafis: additionalMafis, pageInfo: {
                    emptyResult: searchMafis.length === 0,
                    firstPage: searchObject.pageIndex === 0,
                    lastPage: false,
                    pageNumber: searchObject.pageIndex!,
                    pageSize: searchObject.pageLimit!,
                    numberOfElements: 0,
                    totalPages: searchMafis.length + additionalMafis.length / searchObject.pageLimit!,
                    totalElements: searchMafis.length + additionalMafis.length
                },
                availableSearchOptions: {
                    owner: uniqueMafiOwnerCodes,
                    prefix: uniqueMafiPrefixes,
                    category: uniqueCategories,
                    length: uniqueLengths,
                    tara: uniqueTara,
                    lashingMethods: uniqueLashingMethods
                }
            }
        ))
    }
}
