import React, {useEffect, useState} from "react";

// MaterialUI
import {Box, Button, Divider, Grid, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import AttachButtonComponent from "../../components/mafi/attachButton.component";

// Components
import MapComponent from "../../components/map/map.component";
import {showApiError} from "../../misc/errorHelper";
import {ROUTES} from "../../routes/routes";
import {useAppSelector} from "../../store/hooks";
import MafiDetailsComponent from "../../components/mafi/mafiDetails.component";
import {LiveMafiLocationComponent, MafiInformation, MafiNotation} from "@blg/blg-core";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import {setSelectedMafi} from "../../store/slices/mafi";
import {store} from "../../store/store";
import MafiLoadingConditionComponent from "../../components/mafi/mafiLoadingCondition.component";
import {setActiveTutorial, setShowLoading} from "../../store/slices/global";
import {coupleMafi, decoupleMafi, getUpdateMafiNo} from "../../misc/mafiHelper";
import {setLastGridSettings} from "../../store/slices/driver";
import {useThemeContext} from "../../theme/themeContextProvider";
import {defaultContentStyle} from "../bypassDetails.view";
import {SnackbarUtil} from "@blg/blg-core";
import WetDialogComponent from "components/dialogs/wetDialog.component";

const MafiDetailsView: React.FC = () => {
    const {t} = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { isDarkMode } = useThemeContext();

    const lastGridSettings = useAppSelector(state => state.driver).lastGridSettings;
    const attachedMafi = useAppSelector(state => state.mafi).attachedMafi;
    const selectedMafi = useAppSelector(state => state.mafi).selectedMafi!;
    const isAttached = useAppSelector(state => state.mafi).isAttached;
    const driverLatitude = useAppSelector(state => state.driver).latitude;
    const driverLongitude = useAppSelector(state => state.driver).longitude;

    const [mafiInformation, setMafiInformation] = useState<MafiInformation | null>(null);

    const [mafiGridSize, setMafiGridSize] = useState(4);
    const [mapGridSize, setMapGridSize] = useState(4);
    const [mafiInfoGridSize, setMafiInfoGridSize] = useState(4);

    const [notation, setNotation] = useState<MafiNotation | null>(null);
    const [locationValid, setLocationValid] = useState(false);
    const [showMap, setShowMap] = useState(true);
    const [showMafiDetails, setShowMafiDetails] = useState(true);

    const [showWetDialog, setShowWetDialog] = useState(false);

    useEffect(() => {
        store.dispatch(setActiveTutorial("COUPLE"));
        loadMafiDetails();
    }, []);

    async function loadMafiDetails() {
        if (selectedMafi) {
            try {
                store.dispatch(setShowLoading({showLoading: true}));
                await loadSingleMafi();
            } finally {
                store.dispatch(setShowLoading({showLoading: false}));
            }
        }
    }

    async function loadSingleMafi() {
        try {
            store.dispatch(setShowLoading({showLoading: true}));
            const apiResponse = await TugService.instance.allByMafiNo(selectedMafi.mafiNo);
            const activeMafi = MafiInformation.parseFromObject(apiResponse.data[0]);

            if (activeMafi && activeMafi.stackNo) {
                const apiResponse = await TugService.instance.stackByMafiNo(selectedMafi.mafiNo);
                const stackMafis = MafiInformation.parseFromArray(apiResponse.data) as MafiInformation[];

                const activeMafi = stackMafis.find(m => m.mafiNo === selectedMafi.mafiNo);
                activeMafi!.stack = stackMafis.map(m => m.mafiNo);

                store.dispatch(setSelectedMafi(activeMafi));
                setMafiInformation(activeMafi!);
            } else {
                store.dispatch(setSelectedMafi(activeMafi));
                setMafiInformation(activeMafi!);
            }
        } catch (e) {
            showApiError(e);
        } finally {
            store.dispatch(setShowLoading({showLoading: false}));
        }
    }

    useEffect(() => {
        if (!selectedMafi) {
            store.dispatch(setActiveTutorial("COUPLE"));
            setMafiGridSize(0);
            setMafiInfoGridSize(6);
            setMapGridSize(6);
            setShowMap(true);
            setShowMafiDetails(false);
        } else {
            if (selectedMafi.stack.length > 0) {
                store.dispatch(setActiveTutorial("DECOUPLE_STACK"));
            } else {
                store.dispatch(setActiveTutorial("DECOUPLE"));
            }

            if (lastGridSettings) {
                setMafiGridSize(lastGridSettings.mafi);
                setMafiInfoGridSize(lastGridSettings.mafiInfo);
                setMapGridSize(lastGridSettings.map);
                setShowMap(lastGridSettings.map > 1);
                setShowMafiDetails(lastGridSettings.mafi > 1);
            } else {
                setMafiGridSize(4);
                setMafiInfoGridSize(4);
                setMapGridSize(4);
                setShowMafiDetails(true);
            }
        }
    }, [attachedMafi?.id]);

    const attachButtonClicked = async () => {
        await attach(false);
    }

    /**
     * is called when the attach / detach button is usedgma
     */
    async function attach(confirmed: boolean) {
        if (isAttached) {
            if (!confirmed && mafiInformation!._hasWetSensitiveData && !notation!.wetSensitive) {
                setShowWetDialog(true);
                return;
            }

            try {
                store.dispatch(setShowLoading({showLoading: true}));

                const mafiNo = getUpdateMafiNo(selectedMafi);

                // MafiStatus 609 is "loaded". The mafi location should not be updated for >= 609
                if (selectedMafi.mafiStatus < 609) {
                    await TugService.instance.setMafiLocation({
                        mafiNo: mafiNo,
                        location: notation!.notation,
                        lat: driverLatitude!,
                        long: driverLongitude!,
                        handleExternalStock: true
                    });
                }

                if (selectedMafi.loadStatus) {
                    await TugService.instance.setMafiLoadStatus({
                        mafiNo: mafiNo,
                        loadStatus: selectedMafi.loadStatus
                    });
                }

                await decoupleMafi(selectedMafi);
                await loadMafiDetails();
                navigate(ROUTES.MAFI_SEARCH, { replace: true});
            } catch (e) {
                showApiError(e);
            } finally {
                store.dispatch(setShowLoading({showLoading: false}));
            }
        } else {
            await coupleMafi(mafiInformation!);
        }
    }

    function toggleMafiDetails() {
        setShowMafiDetails(!showMafiDetails);
        calculateGridSizes(showMap, !showMafiDetails);
    }

    function toggleMapDetails() {
        setShowMap(!showMap);
        calculateGridSizes(!showMap, showMafiDetails);
    }

    function calculateGridSizes(mapState: boolean, mafiState: boolean) {
        if (mafiState) {
            setMapGridSize(mapState ? 4 : 1);
            setMafiInfoGridSize(mapState ? 4 : 5);
            setMafiGridSize(mapState ? 4 : 6);
            store.dispatch(setLastGridSettings({
                map: mapState ? 4 : 1,
                mafi: mapState ? 4 : 6,
                mafiInfo: mapState ? 4 : 5
            }));
        } else {
            setMapGridSize(mapState ? 6 : 1);
            setMafiInfoGridSize(mapState ? 5 : 10);
            setMafiGridSize(1);

            store.dispatch(setLastGridSettings({
                map: mapState ? 6 : 1,
                mafi: 1,
                mafiInfo: mapState ? 5 : 10
            }));
        }
    }

    const onNotationChanged = (isValid: boolean, value?: MafiNotation) => {
        if (value) {
            setNotation(value);
        }
        setLocationValid(isValid);
    }

    return (
        <Box sx={{
            height: "100%",
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        }}>
            {mafiInformation &&
                <Box sx={{width: '100%', height: '100%', p: 3}}>
                    <Grid container columnSpacing={3} sx={{height: '100%'}}>
                        <Grid item xs={mapGridSize}>
                            <Box sx={{width: "100%", height: "100%"}}>
                                {
                                    <MapComponent showCollapseIcons={selectedMafi !== null}
                                                  collapseEvent={() => toggleMapDetails}
                                                  componentSize={mapGridSize}
                                                  isCollapsed={!showMap} mafiInformation={selectedMafi!}></MapComponent>

                                }
                            </Box>
                        </Grid>
                        {/* left row that shows the mafi number and allows to attach/detach the mafi */}
                        <Grid item xs={mafiInfoGridSize} sx={{height: '100%'}}>
                            <Box sx={{...defaultContentStyle(theme).contentBox, height: '100%', p: 3}}>
                                <Box sx={{
                                    border: '1px solid #D1E0F9',
                                    py: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '12px'
                                }}>
                                    {selectedMafi &&
                                        <Typography
                                            sx={{fontWeight: 400, fontSize: 24}}>{selectedMafi.mafiNo}</Typography>
                                    }
                                </Box>
                                <Box sx={{mt: 2, display: "flex", flexDirection: "column", alignItems: "center"}}>
                                    <AttachButtonComponent isAttached={isAttached}
                                                           isDisabled={isAttached && !locationValid}
                                                           attachEvent={attachButtonClicked}/>
                                    { attachedMafi && isAttached &&
                                        <Button
                                            className={isDarkMode ? "attach-button": ""}
                                            variant={"contained"}
                                            sx={{
                                                backgroundColor: `${isDarkMode ? "rgba(251, 251,252, 0.15)" : theme.palette.primary.main}`,
                                                textTransform: 'none', borderRadius: '12px', width: "100%", mt: 2
                                            }}
                                            onClick={async () => {
                                                const mafiNo = attachedMafi.mafiNo;
                                                await decoupleMafi(attachedMafi!);
                                                SnackbarUtil.success(t("DECOUPLE_DIALOG.SUCCESS", {mafi: mafiNo}));
                                                navigate(ROUTES.MAFI_SEARCH);
                                            }}>
                                            <Typography sx={{fontSize: 24}} color={theme.palette.white.main }>
                                                ohne Location abkoppeln
                                            </Typography>
                                        </Button>
                                    }
                                </Box>
                                <Divider sx={{my: 2, borderColor: theme.palette.greyCustom.main}}/>
                                {attachedMafi && isAttached &&
                                    <Box sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "column",
                                        width: "100%",
                                        borderRadius: '12px'
                                    }}>
                                        <Box sx={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            width: "100%"
                                        }}>
                                            <Typography sx={defaultContentStyle(theme).titleStyle}>
                                                {t('TUG_ORDER_DETAIL.LOADING_CONDITION.STATUS')}
                                            </Typography>
                                            <MafiLoadingConditionComponent mafi={selectedMafi}/>
                                        </Box>
                                        <Box sx={{width: "100%", mt: 3}}>
                                            <Typography sx={defaultContentStyle(theme).titleStyle}>
                                                {t('TUG_ORDER_DETAIL.LOCATION')}
                                            </Typography>
                                            <LiveMafiLocationComponent
                                                dangerousGood={attachedMafi._hasDangerousData}
                                                latitude={driverLatitude}
                                                longitude={driverLongitude}
                                                onNotationChangedCallback={onNotationChanged}
                                            />
                                        </Box>
                                    </Box>
                                }
                            </Box>
                        </Grid>
                        {/* mafi details, shows the different details of a mafi (e.g. payload weight, length etc.) */}
                        {mafiGridSize !== 0 &&
                            <Grid item xs={mafiGridSize} sx={{height: "100%"}}>
                                <Box sx={{width: "100%", height: "100%"}}>
                                    <MafiDetailsComponent
                                        information={selectedMafi}
                                        collapseEvent={() => toggleMafiDetails}
                                        locationIsValid={locationValid}
                                        showCollapseIcons={selectedMafi !== null}
                                        componentSize={mafiGridSize}
                                        isCollapsed={!showMafiDetails}/>
                                </Box>
                            </Grid>

                        }
                    </Grid>
                </Box>
            }
            <WetDialogComponent showDialog={showWetDialog} closeDialog={async (confirmed: boolean) => {
                setShowWetDialog(false);
                if (confirmed) {
                    await attach(confirmed);
                }

            }}></WetDialogComponent>
        </Box>
    );
};

export default MafiDetailsView;
