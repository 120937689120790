import {Box, Button, Divider, Grid, Typography, IconButton} from "@mui/material";
import {Info} from "@material-ui/icons";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import CloseIcon from '@mui/icons-material/Close';
import {CollapseComponentProps} from "../../interfaces/collapseComponentInterface";
import {showApiError} from "../../misc/errorHelper";
import {defaultContentStyle} from "../../views/bypassDetails.view";
import LoadedGoodsDialogComponent from "../dialogs/loadedGoodsDialog.component";
import {useAppSelector} from "../../store/hooks";
import {store} from "../../store/store";
import {setSelectedMafi} from "../../store/slices/mafi";
import TugService from "@blg/blg-core/lib/esm/services/roTrail/roTrailTugService";
import AttachButtonComponent from "./attachButton.component";
import MafiStackComponent from "./mafiStack.component";
import {MafiInformation, SnackbarUtil} from "@blg/blg-core";
import {setShowLoading} from "../../store/slices/global";
import {coupleMafi, decoupleMafi, getUpdateMafiNo} from "../../misc/mafiHelper";
import RfidEventSelectionDialog from "./rfidEventSelectionDialog.component";
import {GoodsData} from "@blg/blg-core/lib/esm/models/roTrail/GoodsDataInterface";

const defaultStyles = {
    iconStyle: {
        border: "1px solid #3669A8",
        backgroundColor: "#FFF",
        width: "44px",
        height: "44px"
    },
    fontTitleStyle: {
        typography: {xl: 'h6', xs: 'caption'}
    },
    fontTextStyle: {
        fontSize: {
            lg: 22,
            xs: 17
        },
        fontWeight: '600',
    },
    titleTextStyle: {
        fontSize: 24,
        fontWeight: 600,
        mb: 1
    }
}

interface MafiDetailsProps {
    showConnectButton?: boolean,
    locationIsValid: boolean,
    information: MafiInformation
}

const MafiDetailsComponent = (props: CollapseComponentProps & MafiDetailsProps) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const isAttached = useAppSelector(state => state.mafi).isAttached;

    const driverLatitude = useAppSelector(state => state.driver).latitude;
    const driverLongitude = useAppSelector(state => state.driver).longitude;

    const [showRfidSelection, setShowRfidSelection] = useState<boolean>(false);
    const [showLoadedDialog, setShowLoadedDialog] = useState(false);

    /**
     * opens dialog with more details
     */
    function openDetailDialog() {
        setShowLoadedDialog(true);
    }

    async function showRfidEventSelection() {
        if (isAttached) {
            await attachClicked();
        } else {
            setShowRfidSelection(true);
        }
    }

    async function attachClicked() {
        if (isAttached) {
            try {
                store.dispatch(setShowLoading({showLoading: true}));

                const mafiNo = getUpdateMafiNo(props.information);

                let response = null;

                // MafiStatus 609 is "loaded". The mafi location should not be updated for >= 609
                if (props.information.mafiStatus < 609) {
                    await TugService.instance.setMafiLocation({
                        mafiNo: mafiNo,
                        location: props.information.mafiNotation!.notation,
                        lat: driverLatitude!,
                        long: driverLongitude!,
                        handleExternalStock: true
                    });
                }

                if (props.information.loadStatus) {
                    response = await TugService.instance.setMafiLoadStatus({
                        mafiNo: mafiNo,
                        loadStatus: props.information.loadStatus
                    });
                }

                if (response) {
                    const updatedMafi = MafiInformation.parseFromObject(response.data);
                    store.dispatch(setSelectedMafi(updatedMafi));
                    await decoupleMafi(updatedMafi);
                } else {
                    await decoupleMafi(props.information);
                }
            } catch (e) {
                showApiError(e);
            } finally {
                store.dispatch(setShowLoading({showLoading: false}));
            }
        } else {
            await coupleMafi(props.information);
        }
    }

    return (
        <Box sx={{height: "100%", width: "100%"}}>
            {props.isCollapsed && props.collapseEvent && props.showCollapseIcons &&
                <Box sx={{...defaultContentStyle(theme).contentBox, height: "100%"}}>
                    <Box sx={{position: "absolute", left: "-22px", top: "calc(0% - 20px)", zIndex: 401}}>
                        <IconButton disableRipple onClick={props.collapseEvent()} color="primary"
                                    sx={defaultStyles.iconStyle}>
                            <Info/>
                        </IconButton>
                    </Box>
                </Box>
            }
            {!props.isCollapsed &&
                <Box sx={{...defaultContentStyle(theme).contentBox, p: 3, height: "100%"}}>
                    {props.collapseEvent && props.showCollapseIcons &&
                        <Box sx={{position: "absolute", left: "-22px", top: "calc(0% - 20px)", zIndex: 401}}>
                            <IconButton disableRipple onClick={props.collapseEvent()} color="primary"
                                        sx={defaultStyles.iconStyle}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>
                    }
                    <Box sx={{height: "100%", overflowY: "auto"}}>
                        {props.information &&
                            <Box>
                                <Box sx={{
                                    flexDirection: 'row',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}>
                                    <Box sx={{display: "flex", flexDirection: "column"}}>
                                        <Typography sx={defaultStyles.titleTextStyle}>
                                            {props.information.mafiNo}
                                        </Typography>
                                        {props.information.mafiLocation &&
                                            <Typography sx={defaultStyles.titleTextStyle}>
                                                {t("GENERAL.LOCATION")}: {props.information.mafiLocation.location}
                                            </Typography>
                                        }
                                    </Box>
                                </Box>
                                <Grid container sx={{mt: 2}}>
                                    <Grid item xs={3}>
                                        <Typography sx={defaultStyles.fontTitleStyle}>
                                            {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.TARA')}
                                        </Typography>
                                        <Typography sx={defaultStyles.fontTextStyle}>
                                            {props.information.tara}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={defaultStyles.fontTitleStyle}>
                                            {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.NETTO')}
                                        </Typography>
                                        <Typography sx={defaultStyles.fontTextStyle}>
                                            {props.information.weightNet}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={defaultStyles.fontTitleStyle}>
                                            {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.ALL')}
                                        </Typography>
                                        <Typography sx={defaultStyles.fontTextStyle}>
                                            {props.information.weightTotal}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Typography sx={defaultStyles.fontTitleStyle}>
                                            {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.PAYLOAD')}
                                        </Typography>
                                        <Typography sx={defaultStyles.fontTextStyle}>
                                            {props.information.weightMaxLoad}
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Divider sx={{mt: 2, borderColor: theme.palette.greyCustom.main}}/>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', py: '10px'}}>
                                    <Typography sx={{fontSize: 24, color: 'black'}}>Länge ft.</Typography>
                                    <Typography sx={{fontSize: 24, fontWeight: 600, color: 'black'}}>
                                        {props.information.length}
                                    </Typography>
                                </Box>
                                <Divider sx={{borderColor: theme.palette.greyCustom.main}}/>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', py: '10px'}}>
                                    <Typography sx={{fontSize: 24, color: 'black'}}>
                                        {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.POOL')}
                                    </Typography>
                                    <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                                        <Typography sx={{fontSize: 24, fontWeight: 600, color: 'black'}}>
                                            {

                                                props.information.pool ?
                                                t(`TUG_ORDER_DETAIL.IS_POOL.${props.information.pool.toString().toUpperCase()}`) :
                                                t("TUG_ORDER_DETAIL.IS_POOL.FALSE")
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{borderColor: theme.palette.greyCustom.main}}/>
                                {props.information.stack.length === 0 &&
                                    <Box sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        py: '20px',
                                        color: theme.palette.primary.main
                                    }}>
                                        <Typography sx={{fontSize: 24, color: 'black'}}>
                                            {t('TUG_ORDER_DETAIL.MAFI_INFORMATION.LOADING_TABLE')}
                                        </Typography>
                                        <Button onClick={openDetailDialog}>
                                            <Info fontSize={"large"}/>
                                        </Button>
                                    </Box>
                                }
                            </Box>
                        }
                        {props.showConnectButton &&
                            <Box sx={{mt: 2}}>
                                <AttachButtonComponent isAttached={isAttached}
                                                       isDisabled={isAttached && !props.locationIsValid}
                                                       attachEvent={showRfidEventSelection}/>
                            </Box>
                        }
                        {props.information && props.information.stack.length > 1 &&
                            <Box>
                                <MafiStackComponent mafi={props.information} unstackDisabled={!isAttached}/>
                            </Box>
                        }
                    </Box>
                </Box>
            }
            {props.information &&
                <LoadedGoodsDialogComponent goods={props.information.goodsData as GoodsData[]}
                                            showDialog={showLoadedDialog}
                                            closeEvent={() => setShowLoadedDialog(false)}/>
            }
            {showRfidSelection &&
                <RfidEventSelectionDialog
                    showDialog={showRfidSelection}
                    selectEvent={async (info) => {
                        if (props.information?.mafiNo === info.epc) {
                            await attachClicked();
                            setShowRfidSelection(false);
                        } else {
                            SnackbarUtil.error("Bitte den ausgewählten Mafi überprüfen");
                        }
                    }
                    }
                    closeEvent={() => {
                        setShowRfidSelection(false);
                    }}
                    highlightedEvent={props.information}/>
            }
        </Box>
    );
}

export default MafiDetailsComponent;
