import * as React from 'react';
import {
    Box, Dialog, DialogTitle, DialogContent, DialogActions,
    Typography, Button, ButtonBase, Autocomplete, TextField, SxProps, createFilterOptions
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import {RFIDEvent, RFIDResponse} from "../../interfaces/RFID/RFIDResponse";
import { MafiInformation } from "@blg/blg-core";
import {showApiError} from "../../misc/errorHelper";
import LocalForageHelper from "../../misc/localforageHelper";
import RfidService from 'services/rfidService';
import {useThemeContext} from "../../theme/themeContextProvider";
import AttachButtonComponent from "./attachButton.component";

/**
 * checks if the application is running on scanner or in browser
 */
const isScanner = () => {
    return window.api !== undefined;
}

export interface RfidEventSelectionProps {
    showDialog: boolean,
    highlightedEvent?: MafiInformation,

    selectEvent(mafiInformation: RFIDEvent): any,

    closeEvent(): any
}

const sx: SxProps = {
    "& .MuiDialog-container": {
        alignItems: "flex-start"
    }
};

const OPTIONS_LIMIT = 8;
const filterOptions = createFilterOptions({
    limit: OPTIONS_LIMIT
});

const RfidEventSelectionDialog = (props: RfidEventSelectionProps) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {isDarkMode} = useThemeContext();
    const [open, setOpen] = React.useState(false);

    const [availableMafis, setAvailableMafis] = useState<RFIDEvent[]>([]);
    const [allMafis, setAllMafis] = useState<MafiInformation[]>([]);
    const [manuallySelection, setManuallySelection] = useState<string | null>(null)

    const hexToAscii = (value: string): string => {
        let str = '';
        for (let i = 0; i < value.length; i += 2)
            str += String.fromCharCode(parseInt(value.substr(i, 2), 16));
        return str;
    }

    useEffect(() => {
        return () => {
            setManuallySelection(null);
        }
    }, [])

    useEffect(() => {
        if (props.showDialog) {
            triggerMafiScan();
        }
        setOpen(props.showDialog);
    }, [props.showDialog]);

    async function triggerMafiScan() {
        const selectionTag = props.highlightedEvent ? {epc: props.highlightedEvent.mafiNo, rssi: 40} : null
        const mafis = await LocalForageHelper.instance.loadAllMafis();
        setAllMafis(mafis);
        if (isScanner()) {
            let foundTags: RFIDEvent[] = [];
            try {
                const apiResponse = await RfidService.instance.triggerMafiScan();
                const rfidResponse = apiResponse.data as RFIDResponse;
                foundTags = rfidResponse.events.map((tag) => {
                    return {...tag, epc: hexToAscii(tag.epc).replace(/[^\w\s]/gi, '')}
                })
            } catch (e) {
                showApiError(e);
            }

            setAvailableMafis(foundTags);
        } else {
            // Get three random mafis for the selection
            const mafiSelection = mafis.sort(() => Math.random() - Math.random()).slice(0, 3);
            const tags: RFIDEvent[] = mafiSelection.map((mafi: MafiInformation) => {
                return {epc: mafi.mafiNo, rssi: 40};
            })
            setAvailableMafis(selectionTag ? [{ epc: selectionTag.epc, rssi: 40 }] : tags);
        }
    }

    const rfidElements = availableMafis.map((mafi) => {
        return (<Box sx={{mb: 2}}
                     component={ButtonBase}
            // Small delay to show the ripple effect
                     onClick={() => {
                         setTimeout(() => {
                             props.selectEvent(mafi);
                         }, 250);
                     }}
                     key={mafi.epc}>
            <Box sx={{
                display: "flex", flexDirection: "column", p: 3, width: "100%",
                color: theme.palette.primary.main, borderRadius: "6px",
                backgroundColor: theme.palette.surface.main,
                border: `${props.highlightedEvent && props.highlightedEvent.mafiNo === mafi.epc ?
                    `2px solid red` :
                    `1px solid ${theme.palette.primary.main}`}`
            }}>
                <Typography variant={"h6"}>
                    {mafi.epc}
                </Typography>
            </Box>
        </Box>)
    });

    return (
        <Box>
            <Dialog
                sx={window.api ? sx: null}
                fullWidth
                open={open}
            >
                <DialogTitle>
                    Mafi-Auswahl
                </DialogTitle>
                <DialogContent sx={{minHeight: "375px"}}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <Autocomplete
                                filterOptions={filterOptions}
                                className={isDarkMode ? "rfid-autocomplete": ""}
                                disablePortal
                                onChange={(event, value: any) => {
                                    setManuallySelection(value.mafiNo);
                                }}
                                options={allMafis}
                                sx={{minWidth: 350, py: 1}}
                                getOptionLabel={(option) => option.mafiNo}
                                renderInput={(params) =>
                                    <TextField {...params} inputProps={{ ...params.inputProps, inputMode: 'numeric' }} label="MafiNo"/>}
                            />
                            <Box sx={{ml: 3}}>
                                <AttachButtonComponent isAttached={false} isDisabled={!manuallySelection}
                                                       attachEvent={() => {
                                                           const mafi = allMafis.find(m => m.mafiNo === manuallySelection);
                                                           if (mafi) {
                                                               props.selectEvent({epc: mafi.mafiNo, rssi: 0});
                                                           }
                                                       }}></AttachButtonComponent>
                            </Box>
                        </Box>
                        <Typography sx={{mb: 3}} variant={"body2"}>Folgende Tags wurden gefunden:</Typography>
                        {
                            rfidElements
                        }
                        { availableMafis.length === 0 &&
                            <Typography sx={{mt: 3, textAlign: "center"}} variant={"body2"}>Keine Tags gefunden</Typography>
                        }
                    </Box>
                </DialogContent>
                <DialogActions sx={{p: 3}}>
                    <Button
                        startIcon={<CloseIcon/>}
                        variant={"outlined"}
                        sx={{color: `${theme.palette.primary.main}`}}
                        onClick={props.closeEvent}>
                        {t("GENERAL.CANCEL")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default RfidEventSelectionDialog;
