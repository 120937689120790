import React, {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import {Box, Button, Dialog, Grid, IconButton, Typography} from "@mui/material";
import {Close} from "@material-ui/icons";
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {MafiCategory} from "@blg/blg-core";
import {useAppSelector} from "../../store/hooks";
import {setLastSearchSettings} from "../../store/slices/searchSettings";
import {store} from "../../store/store";
import {AvailableSearchOptions} from "../../utils/mafiSearchHelper";
import AHBMafiFilterContainerComponent from "./ahbMafiFilterContainer.component";
import CategoryFilterContainerComponent from "./categoryFilterContainer.component";
import {MafiFilterEntry} from "./filterElement.component";
import LashingMethodFilterContainerComponent from "./lashingMethodFilterContainer.component";
import LengthFilterContainerComponent from "./lengthFilterContainer.component";
import LoadingStatusFilterContainerComponent from "./loadingStatusFilterContainer.component";
import PoolMafiFilterContainerComponent from "./poolMafiFilterContainer.component";
import TaraFilterContainerComponent from "./taraFilterContainer.component";
import {allLashingMethods, allLengthEntries, allLoadingStatus, allTaraEntries} from "../../misc/filterOptions";

/**
 * different props that can be passed to the dialog
 */
export interface CategoryDialogProps {
    availableSearchOptions?: AvailableSearchOptions,
    allMafiCategories: MafiCategory[],
    open?: boolean,
    onCloseDialog?: () => void,
    onFilterChanged?: (filters: FilterState) => void
}

export type FilterState = {
    categories: MafiCategory[],
    length: MafiFilterEntry[],
    tara: MafiFilterEntry[],
    lashingMethods: string[],
    loadingStatus: string[],
    showOnlyPoolMafi: boolean,
    showAHB: boolean
};

export interface CategoryDialogRef {
    resetFilter: () => void;
}

const CategoryDialogComponent = forwardRef<CategoryDialogRef, CategoryDialogProps>((props, ref) => {
    const {t} = useTranslation();
    const theme = useTheme();

    const lastSearchSettings = useAppSelector(state => state.searchSettings).lastSearchSettings;

    const [activeFilters, setActiveFilters] = useState<FilterState>({
        categories: [],
        length: [],
        tara: [],
        lashingMethods: [],
        loadingStatus: [],
        showOnlyPoolMafi: false,
        showAHB: false
    });

    useEffect(() => {
        if (lastSearchSettings) {
            setActiveFilters({
                categories: lastSearchSettings.categories,
                length: lastSearchSettings.length,
                tara: lastSearchSettings.tara,
                lashingMethods: lastSearchSettings.lashingMethods,
                loadingStatus: lastSearchSettings.loadingStatus,
                showOnlyPoolMafi: lastSearchSettings.showOnlyPoolMafi,
                showAHB: lastSearchSettings.showAHB
            });
        }
    }, []);

    useEffect(() => {
        props.onFilterChanged?.(activeFilters);
    }, [activeFilters]);

    useImperativeHandle(ref, () => ({
        resetFilter() {
            setActiveFilters({
                categories: [],
                length: [],
                tara: [],
                lashingMethods: [],
                loadingStatus: [],
                showOnlyPoolMafi: false,
                showAHB: false
            });
        }
    }));

    /**
     * is called when the close button is tapped, closes the dialog without changing the
     * selected category
     */
    function closeCategoryDialog() {
        props.onCloseDialog?.call(null);
    }

    function onResetFilter() {
        setActiveFilters({
            categories: [],
            length: [],
            tara: [],
            lashingMethods: [],
            loadingStatus: [],
            showOnlyPoolMafi: false,
            showAHB: false
        });

        store.dispatch(setLastSearchSettings( {
            ...lastSearchSettings,
            categories: [],
            length: [],
            tara: [],
            lashingMethods: [],
            loadingStatus: [],
            showOnlyPoolMafi: false,
            showAHB: false
        }));

        closeCategoryDialog()
    }

    function onSaveFilter() {
        closeCategoryDialog()
    }

    return <Dialog
        maxWidth={"lg"}
        fullWidth
        sx={{p: 0, m: 0, display: 'flex', justifyContent: 'center'}}
        onClose={closeCategoryDialog}
        open={props.open ?? false}>
        <Box sx={{width: 966, height: 675, backgroundColor: theme.palette.background.default, p: 3,
        display: "flex", flexDirection: "column"}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mb: 4, alignItems: 'center'}}>
                <Typography
                    sx={{fontSize: 24, fontWeight: 600}}>{t('MAFI_SEARCH.EXTENDED_FILTERS')}</Typography>
                <IconButton size={"large"} onClick={() => closeCategoryDialog()}><Close
                    fontSize={"medium"}/></IconButton>
            </Box>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <CategoryFilterContainerComponent
                        mafiCategories={props.allMafiCategories}
                        currentSelectedCategory={activeFilters.categories}
                        onCategoryChanged={(categories => {
                            setActiveFilters(prevState => {
                                return {
                                    ...prevState,
                                    categories: categories
                                }
                            })
                        })}></CategoryFilterContainerComponent>
                </Grid>
                <Grid item xs={3}>
                    <LengthFilterContainerComponent
                        allLengthEntries={allLengthEntries}
                        currentSelectedLength={activeFilters.length}
                        onLengthChanged={(length => {
                            setActiveFilters(prevState => {
                                return {
                                    ...prevState,
                                    length: length
                                }
                            })
                        })}></LengthFilterContainerComponent>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} item xs={3}>
                    <TaraFilterContainerComponent
                        allTaraEntries={allTaraEntries}
                        currentSelectedTara={activeFilters.tara}
                        onTaraChanged={(tara => {
                            setActiveFilters(prevState => {
                                return {
                                    ...prevState,
                                    tara: tara
                                }
                            })
                        })}></TaraFilterContainerComponent>
                    <Box sx={{ mt: 3 }}>
                        <PoolMafiFilterContainerComponent
                            poolMafiFilter={activeFilters.showOnlyPoolMafi}
                            onPoolFilterChanged={(poolMafiFilter => {
                                setActiveFilters(prevState => {
                                    return {
                                        ...prevState,
                                        showOnlyPoolMafi: poolMafiFilter
                                    }
                                })
                            })}></PoolMafiFilterContainerComponent>
                    </Box>
                </Grid>
                <Grid sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }} item xs={3}>
                    <LashingMethodFilterContainerComponent
                        lashingMethods={allLashingMethods}
                        onLashingMethodChanged={(lashingMethods => {
                            setActiveFilters(prevState => {
                                return {
                                    ...prevState,
                                    lashingMethods: lashingMethods
                                }
                            })
                        })}
                        currentSelectedMethods={activeFilters.lashingMethods}></LashingMethodFilterContainerComponent>
                    <LoadingStatusFilterContainerComponent
                        loadingStatus={allLoadingStatus}
                        onLoadingStatusChanged={(status => {
                            setActiveFilters(prevState => {
                                return {
                                    ...prevState,
                                    loadingStatus: status
                                }
                            })
                        })}
                        currentSelectedStatus={activeFilters.loadingStatus}></LoadingStatusFilterContainerComponent>
                    {  /*
                                            <AHBMafiFilterContainerComponent ahbMafiFilter={activeFilters.showAHB} onAHBFilterChanged={(showAHB) =>
                        setActiveFilters(prevState => {
                            return {
                                ...prevState,
                                showAHB: showAHB
                            }
                        })
                    }/>
                        */
                    }

                </Grid>
            </Grid>
            <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 4}}>
                <Button
                    onClick={onResetFilter}
                    variant={"contained"}
                    sx={{
                        textTransform: 'none', py: '19px', px: '40px', fontSize: 18, fontWeight: '500',
                        lineHeight: '20px'
                    }}>{t('GENERAL.RESET')}
                </Button>
                <Button
                    onClick={onSaveFilter}
                    variant={"contained"}
                    sx={{
                        textTransform: 'none', py: '19px', px: '40px', fontSize: 18, fontWeight: '500',
                        lineHeight: '20px'
                    }}>{t('GENERAL.NEXT')}</Button>
            </Box>
        </Box>
    </Dialog>;
});

CategoryDialogComponent.displayName = 'CategoryDialogComponent';

export default CategoryDialogComponent;
