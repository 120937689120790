import {createSlice, PayloadAction} from '@reduxjs/toolkit'

export interface AuthState {
    username?: string
}

const initialState: AuthState = {username: undefined};

const authState = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setUsername(state: AuthState, action: PayloadAction<string>) {
            state.username = action.payload
        },
    },
});

export const {setUsername} = authState.actions;
export default authState.reducer;
